// // Wait for page to load
document.addEventListener("DOMContentLoaded", function () {
  // Get the current time and date
  let currentTime = new Date();

  // Check if popup has been show to the user already
  if (!sessionStorage.getItem("popUpShown")) {
    // Check if the current day is Monday to Friday
    if (currentTime.getDay() >= 1 && currentTime.getDay() <= 5) {
      // Check if the current time is between 6am and 6pm
      if (currentTime.getHours() >= 6 && currentTime.getHours() < 18) {
        // If it is, display a popup to the user
        //   console.log("It's currently between 6am and 6pm on a weekday.");
        toggleModal();
        sessionStorage.setItem("popUpShown", true);
      }
    }
    // Check if the current day is Saturday
    else if (currentTime.getDay() == 6) {
      // Check if the current time is between 6am and noon
      if (currentTime.getHours() >= 6 && currentTime.getHours() < 18) {
        // If it is, display a popup to the user
        //   console.log("It's currently between 6am and noon on Saturday.");
        toggleModal();
        sessionStorage.setItem("popUpShown", true);
      }
    }
  }

  // Function to toggle open and close the modal
  function toggleModal() {
    document.getElementById("popup-wrapper").classList.toggle("tw-hidden");
    document.getElementById("popup-wrapper").classList.toggle("tw-flex");
    document.body.classList.toggle("tw-overflow-hidden");
  }

  // When the user clicks the close button or anywhere outside of the modal, close it
  window.onclick = function (event) {
    if (event.target == document.getElementById("closePopup")) {
      toggleModal();
    }
  };
});
